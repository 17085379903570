html, body {
    height: 100%;
}
body {
    background: url("background.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0;
    overflow-x: hidden;
}