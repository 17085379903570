div.login_prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

p.error_message {
    color: crimson;
}

div.prompt_box {
    border: 5px solid #e5571b;
    padding: 20px;
    background: #f1c27c;
}

div.prompt_username {
    color: #57008a;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: Arial, Helvetica, sans-serif;
}

p.prompt_username_label {
    align-items: start;
}

div.prompt_password {
    color: #57008a;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: Arial, Helvetica, sans-serif;
}

p.prompt_password_label {
    align-items: start;
}

div.prompt_submit {
    margin: 1em;
}